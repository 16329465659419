var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "v-layout",
        {
          staticClass: "goals-card",
          attrs: { row: "", wrap: "", "pt-3": "", "gap-xs-3": "" }
        },
        [
          _c("v-flex", { attrs: { xs12: "" } }, [
            _c("p", { staticClass: "starling-text" }, [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "app.tools.goal_setter." +
                      (_vm.hasGoals ? "title_other" : "title_first")
                  )
                )
              )
            ])
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-card",
                {
                  staticClass:
                    "create-goal-card d-flex align-center justify-center",
                  attrs: { flat: "" }
                },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        "justify-center": "",
                        "align-center": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [
                          _c(
                            "primary-button",
                            {
                              attrs: { depressed: "" },
                              on: { click: _vm.onCreate }
                            },
                            [
                              _c("label", { staticClass: "mx-3" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "app.tools.goal_setter.actions.create"
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.startedGoals.length
            ? [
                _c(
                  "v-flex",
                  { attrs: { xs12: "" } },
                  [
                    _c(
                      "h3",
                      { staticClass: "starling-h3 StarlingPrimary2--text" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("app.tools.goal_setter.sections.started")
                          )
                        )
                      ]
                    ),
                    _c("goal-list", {
                      attrs: { goals: _vm.startedGoals },
                      on: { edit: _vm.onEdit, update: _vm.refreshGoals }
                    })
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm.upcomingGoals.length
            ? [
                _c(
                  "v-flex",
                  { attrs: { xs12: "" } },
                  [
                    _c(
                      "h3",
                      { staticClass: "starling-h3 StarlingPrimary2--text" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("app.tools.goal_setter.sections.upcoming")
                          )
                        )
                      ]
                    ),
                    _c("goal-list", {
                      attrs: { goals: _vm.upcomingGoals },
                      on: { edit: _vm.onEdit, update: _vm.refreshGoals }
                    })
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm.pausedGoals.length
            ? [
                _c(
                  "v-flex",
                  { attrs: { xs12: "" } },
                  [
                    _c(
                      "h3",
                      { staticClass: "starling-h3 StarlingPrimary2--text" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("app.tools.goal_setter.sections.paused")
                          )
                        )
                      ]
                    ),
                    _c("goal-list", {
                      attrs: { goals: _vm.pausedGoals },
                      on: { edit: _vm.onEdit, update: _vm.refreshGoals }
                    })
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm.completedGoals.length
            ? [
                _c(
                  "v-flex",
                  { attrs: { xs12: "" } },
                  [
                    _c(
                      "h3",
                      { staticClass: "starling-h3 StarlingPrimary2--text" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("app.tools.goal_setter.sections.completed")
                          )
                        )
                      ]
                    ),
                    _c("goal-list", {
                      attrs: { goals: _vm.completedGoals },
                      on: { edit: _vm.onEdit, update: _vm.refreshGoals }
                    })
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }