
















































import Vue from 'vue';
import { IGoalGroup, IGoalSetter } from '@/scripts/store/modules/tools/goals/types';
import GoalList from '@/views/tools/goals/components/goal-list.vue';
import primaryButton from '@/views/components/button/primary-button.vue';

export default Vue.extend({
  name: 'goalsView',
  components: {
    GoalList,
    primaryButton,
  },
  data() {
    return {
      creating: false,
      loading: false,
      form: {},
      createMode: false,
    };
  },
  computed: {
    storeGoals(): IGoalGroup {
      return this.$store.getters['goals/goals'];
    },
    startedGoals(): IGoalSetter[] {
      return this.storeGoals.startedGoals || [];
    },
    upcomingGoals(): IGoalSetter[] {
      return this.storeGoals.upcomingGoals || [];
    },
    pausedGoals(): IGoalSetter[] {
      return this.storeGoals.pausedGoals || [];
    },
    completedGoals(): IGoalSetter[] {
      return this.storeGoals.completedGoals || [];
    },
    hasGoals(): Boolean {
      return !!this.storeGoals.upcomingGoals?.length || !!this.storeGoals.startedGoals?.length || !!this.storeGoals.pausedGoals?.length || !!this.storeGoals.completedGoals?.length;
    },
  },
  mounted() {
    this.loading = true;
    this.refreshGoals();
  },
  methods: {
    refreshGoals() {
      this.$store.dispatch('goals/getGoals').finally(() => {
        this.loading = false;
      });
    },
    onCreate() {
      this.$router.push({ name: 'goal-create' });
    },
    onEdit(goal: IGoalSetter) {
      this.$router.push({ name: 'goal-edit', params: { id: goal.id as string } });
    },
  },
});
