

























import Vue from 'vue';
import { GoalStatusEnum, IGoalSetter } from '@/scripts/store/modules/tools/goals/types';
import goalCard from '@/views/tools/goals/components/goal-card.vue';

export default Vue.extend({
  name: 'goal-list',
  components: {
    goalCard,
  },
  props: {
    goals: {
      type: Array as () => Array<IGoalSetter>,
      required: true,
    },
  },
  data() {
    return {
      page: 1,
      goalStatus: GoalStatusEnum,
    };
  },
  computed: {
    goalsPage(): Array<IGoalSetter> {
      if (!this.goals.length) return [];
      const iniPos = (this.page - 1) * 5;
      const endPos = this.page * 5;
      return this.goals.slice(iniPos, endPos);
    },
    paginateLength(): number {
      if (!this.goals.length) return 1;
      return Math.floor((this.goals.length) / 5) + ((this.goals.length % 5) && 1);
    },
  },
  methods: {
    onEdit(goal: IGoalSetter) {
      this.$emit('edit', goal);
    },
    onUpdate() {
      this.$emit('update');
    },
  },
});
